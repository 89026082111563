body {
  height: 100vh;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

html {
  height: -webkit-fill-available;
}

/* Make sure the __next wrapper is flex layout to fill the entire page */
#__next {
  display: flex;
  flex-direction: column;
  flex: 1 0;
}
